"use client"

import { fetchDataWithToken } from "@/api"
import { login, validateLogin } from "@/api/auth/login"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  cekConnectAuthGoogle,
  getUrlAuthGoogle,
} from "@/data-access/auth/get-auth"
import { LoginFormSchema } from "@/lib/zod/zodSchema"
import { AUTH_ROUTE, redirectAfterLogin } from "@/routes"
// import { redirectAfterLogin } from "@/routes"
import { zodResolver } from "@hookform/resolvers/zod"
import { Loader2 } from "lucide-react"
import { useSession } from "next-auth/react"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { useState, useTransition } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"

export function LoginForm({ authGoogleUrl }) {
  const { data: session } = useSession()
  const role = session?.user?.role
  const router = useRouter()
  const [isPending, startTransition] = useTransition()
  const form = useForm({
    resolver: zodResolver(LoginFormSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  })

  function onSubmit(values) {
    startTransition(() => {
      login(values).then((data) => {
        if (data?.error) {
          toast.error(data.error)
        } else {
          toast.success("Success to login")
          router.push("/onboarding")
          // if (role === "mahasiswa") {
          //   router.push("/mahasiswa")
          // } else {
          //   router.push("/dosen")
          // }
        }
      })
    })
  }
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="lg:w-6/12 w-full space-y-6">
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  disabled={isPending}
                  placeholder="nim"
                  autoComplete="off"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  disabled={isPending}
                  placeholder="password"
                  type="password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          {isPending ? (
            <Button disabled className="w-full mb-3">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Tunggu sebentar
            </Button>
          ) : (
            <Button type="submit" className="w-full mb-3">
              Masuk
            </Button>
          )}
          <div className="flex justify-center items-center gap-3">
            <div className="p-[1px]  w-full bg-slate-300"></div>
            <p>or</p>
            <div className="p-[1px] bg-slate-300 w-full"></div>
          </div>
          <Link href={authGoogleUrl}>
            <Button variant="secondary" type="button" className="w-full gap-4">
              <Image
                src={"/icons/google.svg"}
                width={20}
                height={20}
                alt="google"
              />
              Sign in with Google
            </Button>
          </Link>
        </div>
      </form>
    </Form>
  )
}
